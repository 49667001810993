@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.modal-sheet-left-enter {
  animation: slideInLeft 0.5s ease-in-out;
}

.modal-sheet-left-exit {
  animation: slideOutLeft 0.5s ease-in-out;
}

.modal-sheet-right-enter {
  animation: slideInRight 0.5s ease-in-out;
}

.modal-sheet-right-exit {
  animation: slideOutRight 0.5s ease-in-out;
}
